// Layout level ////////////////////////////////////////////////////////////////

body {
  font-size: 11px;
  margin: 0;
  letter-spacing: 0.5px;
}


.loader {
  html.loading & {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    width: auto;
  }
  display: none;
}


#content,
header {
  transition: opacity slow;
}

html.loading #content,
html.loading header {
  opacity: 0;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}


.color {
  background-color: #F8F5F4;
}

a {
  color: #000;
  text-decoration: none;
}


h1.name-header {
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  position: absolute;
   a {
    display: inline-block;
    padding: 18px 20px 18px;
    transition-duration: slow;
    &:hover {
      transition-duration: fast;
    }
  }

  .mobile {
    display: inline-block;

    @media tablet-portrait {
      display: none;
    }
  }
  .desktop {
    display: none;

    @media tablet-portrait {
      display: inline-block;
    }

  }
}

.nav {
  text-align: center;
  display: flex;
  justify-content: center;
  a {
    display: inline-block;
    font-size: 11px;
    padding: 19px 10px 19px;
    transition: color slow;
    &:hover {
      transition-duration: fast;
    }
  }
}



// Hover State
header a:hover,
.nav a.current,
.nav.about a.about,
.nav.contact a.contact {
  color: hsl(0, 0%, 65%);
}


.esc {
  top: 0;
  left: 0;
  float: left;
  display: none;
  z-index: 100;
  font-size: 11px;
  font-weight: bold;
  &:hover {
    background: #000;
    color: #f6d4e7;
    cursor: pointer;
  }
}


#content {
  margin: 40px 10px 0;
  overflow: hidden;
}



.description-wrap {
  display: table;
  height: 100%;
  margin: 0 auto;
}


.permalink {
  .image {
    display: none;
    &:first-child {
      display: block;
    }
  }
}


.permapage .image{
  display: block;
}

.close {
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 18px;
  transition-duration: slow;
  &:hover {
    color: #CFCAC0;
    transition-duration: fast;
  }
}

a.post {
  cursor: pointer;
  display: block;
  width: 20%;

  box-sizing: border-box;
  padding: 10px;

  &.loading {
    pointer-events: none;
    img {
      opacity: 0;
    }
  }



  .image {
    transition-duration: slow;
    transition-property: opacity;
    width: 100%;
  }
  .caption {
    color: transparent;
    display: block;
    transition-duration: slow;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  &:hover {
    .image {
      opacity: 0.20;
      transition-duration: fast;
    }
    .caption {
      color: #000;
      transition-duration: fast;
    }
  }


}




.grid-sizer.image {
  @media (max-width: 500px) {
    width: 100vw;
    margin-bottom: 20px;
  }
}