body {
  font-size: 11px;
  margin: 0;
  letter-spacing: 0.5px;
}
.loader {
  display: none;
}
html.loading .loader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
  width: auto;
}
#content,
header {
  transition: opacity 800ms;
}
html.loading #content,
html.loading header {
  opacity: 0;
}
header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}
.color {
  background-color: #f8f5f4;
}
a {
  color: #000;
  text-decoration: none;
}
h1.name-header {
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  position: absolute;
}
h1.name-header a {
  display: inline-block;
  padding: 18px 20px 18px;
  transition-duration: 800ms;
}
h1.name-header a:hover {
  transition-duration: 400ms;
}
h1.name-header .mobile {
  display: inline-block;
}
@media (min-width: 768px) {
  h1.name-header .mobile {
    display: none;
  }
}
h1.name-header .desktop {
  display: none;
}
@media (min-width: 768px) {
  h1.name-header .desktop {
    display: inline-block;
  }
}
.nav {
  text-align: center;
  display: flex;
  justify-content: center;
}
.nav a {
  display: inline-block;
  font-size: 11px;
  padding: 19px 10px 19px;
  transition: color 800ms;
}
.nav a:hover {
  transition-duration: 400ms;
}
header a:hover,
.nav a.current,
.nav.about a.about,
.nav.contact a.contact {
  color: #a6a6a6;
}
.esc {
  top: 0;
  left: 0;
  float: left;
  display: none;
  z-index: 100;
  font-size: 11px;
  font-weight: bold;
}
.esc:hover {
  background: #000;
  color: #f6d4e7;
  cursor: pointer;
}
#content {
  margin: 40px 10px 0;
  overflow: hidden;
}
.description-wrap {
  display: table;
  height: 100%;
  margin: 0 auto;
}
.permalink .image {
  display: none;
}
.permalink .image:first-child {
  display: block;
}
.permapage .image {
  display: block;
}
.close {
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 18px;
  transition-duration: 800ms;
}
.close:hover {
  color: #cfcac0;
  transition-duration: 400ms;
}
a.post {
  cursor: pointer;
  display: block;
  width: 20%;
  box-sizing: border-box;
  padding: 10px;
}
a.post.loading {
  pointer-events: none;
}
a.post.loading img {
  opacity: 0;
}
a.post .image {
  transition-duration: 800ms;
  transition-property: opacity;
  width: 100%;
}
a.post .caption {
  color: transparent;
  display: block;
  transition-duration: 800ms;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
a.post:hover .image {
  opacity: 0.2;
  transition-duration: 400ms;
}
a.post:hover .caption {
  color: #000;
  transition-duration: 400ms;
}
@media (max-width: 500px) {
  .grid-sizer.image {
    width: 100vw;
    margin-bottom: 20px;
  }
}
.img {
  background: #000;
}
.cross:hover {
  opacity: 0.3;
  cursor: pointer;
}
.goback {
  font-size: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 5px 10px 5px 10px;
  margin: 20px;
}
arrow,
#back {
  float: left;
  display: inline;
}
arrow {
  font-size: 13px;
}
#back {
  margin: 2px 0 0 2px;
}
.goback:hover {
  background: #f7f2f2;
  transition-duration: 400ms;
}
.cross {
  cursor: pointer;
}
.image {
  cursor: pointer;
}
.grid-sizer {
  width: 25%;
}
.grid-sizer img {
  width: 100%;
}
#cross {
  cursor: pointer;
}
#content.index-page a.permalink.post.photoset.index-page {
  position: relative;
  width: 50%;
}
@media mobile-landscape {
  #content.index-page a.permalink.post.photoset.index-page {
    width: calc(100% / 3);
  }
}
@media (min-width: 1024px) {
  #content.index-page a.permalink.post.photoset.index-page {
    width: 25%;
  }
}
#content.index-page a.permalink.post.photoset.index-page img {
  display: block;
  width: 100%;
}
#content.index-page a.permalink.post.photoset.index-page .caption {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
#content.permalink-page h1.caption {
  display: block;
  font-size: 11px;
  font-weight: normal;
  position: relative;
  text-align: center;
  z-index: 1;
}
#content.permalink-page .post.photoset {
  font-size: 0;
  position: relative;
}
#content.permalink-page .post.photoset .thumbnail {
  position: relative;
  width: 50%;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
}
@media mobile-landscape {
  #content.permalink-page .post.photoset .thumbnail {
    width: calc(100% / 3);
  }
}
@media (min-width: 1024px) {
  #content.permalink-page .post.photoset .thumbnail {
    width: 25%;
  }
}
#content.permalink-page .post.photoset .thumbnail img {
  display: block;
  pointer-events: none;
  width: 100%;
  transition-duration: 800ms;
}
#content.permalink-page .post.photoset .thumbnail .caption {
  font-size: 11px;
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  text-align: center;
  transform: translateY(-50%);
  color: transparent;
  transition-duration: 800ms;
}
#content.permalink-page .post.photoset .thumbnail:hover img {
  opacity: 0.2;
  transition-duration: 400ms;
}
#content.permalink-page .post.photoset .thumbnail:hover .caption {
  color: #000;
  transition-duration: 400ms;
}
@media screen and (max-height: 780px) {
  .lightbox .image {
    max-height: 600px;
  }
}
.post.photoset.permapage {
  font-size: 0;
}
.photo .grid-sizer {
  cursor: pointer;
  display: block;
  pointer-events: none;
  width: 100%;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 80%;
}
.lightbox span.photo {
  display: block;
  position: static;
}
.lightbox span.photo img {
  display: block;
  opacity: 0;
  transition: opacity 400ms ease-out;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lightbox span.photo .caption {
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  display: block;
  line-height: 50px;
  transform: translateY(50px);
  transition: opacity 400ms ease-out;
}
.lightbox span.photo.current img {
  opacity: 1;
  z-index: -1;
  transition: opacity 400ms ease-in 400ms;
}
.lightbox span.photo.current img.loading {
  opacity: 0;
}
.lightbox span.photo.current .caption {
  opacity: 1;
  transition: opacity 400ms ease-in 400ms;
}
.lightbox-wrapper {
  position: relative;
}
.capture {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
}
.lightbox {
  border: 50px solid transparent;
  pointer-events: none;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition-duration: 800ms;
}
.lightbox.active {
  opacity: 1;
  pointer-events: auto;
}
.lightbox.active .capture {
  pointer-events: auto !important;
}
.lightbox.active .right.capture {
  right: 0;
  cursor: e-resize;
}
.lightbox.active .left.capture {
  left: 0;
  cursor: w-resize;
}
.lightbox.active .esc {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
}
.lightbox.active + .esc {
  display: block !important;
}
.closebox {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: -50px;
  right: 0;
  margin: 0;
  padding: 15px 0;
  z-index: 400;
  line-height: 1;
  width: 50px;
  text-align: center;
}
.xspot {
  background: url("http://static.tumblr.com/nnzrmfd/PfZnfm84f/smallx.png");
  width: 19px;
  height: 18px;
  margin: 0 auto;
  background-position: 0 0;
  margin-top: 20px;
}
.xspot:hover {
  cursor: pointer;
  opacity: 0.6;
  transition-duration: 400ms;
}
body.post header,
body.post .lightbox {
  background: rgba(255,255,255,0.7);
}
#about,
#contact {
  opacity: 0;
  pointer-events: none;
  transition-duration: 800ms;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#about.expand,
#contact.expand {
  opacity: 1;
  pointer-events: auto;
}
#about .description,
#contact .description {
  display: table-cell;
  vertical-align: middle;
  width: 500px;
  text-align: center;
  font-size: 11px;
  line-height: 1.5;
}
