tablet-portrait = "(min-width: 768px)"
desktop = "(min-width: 1024px)"

fast = 400ms
slow = 800ms

@import "layout";

// Main Page ///////////////////////////////////////////////////////////////////
.img {
  background: #000;
}

.loading {}


.cross:hover {
  opacity: 0.3;
  cursor: pointer;
}

.goback {
  font-size: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  padding: 5px 10px 5px 10px;
  margin: 20px;
}


arrow,
#back {
  float: left;
  display: inline;
}

arrow {
  font-size: 13px;
}

#back {
  margin: 2px 0 0 2px;
}

.goback:hover {
  background: #F7F2F2;
  transition-duration: fast;
}

.cross { cursor: pointer }
.image { cursor: pointer }


.grid-sizer {
  width: 25%;
}


.grid-sizer img {
  width: 100%;
}

#cross { cursor: pointer }


// Index Page //////////////////////////////////////////////////////////////////
#content.index-page {

  a.permalink.post.photoset.index-page {
    position: relative;
    width: 50%;

    @media mobile-landscape {
      width: calc(100%/3);
    }

    @media desktop {
      width: 25%;
    }


    img {
      display: block;
      width: 100%;
    }
    .caption {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }


}


// Permalink Page //////////////////////////////////////////////////////////////


#content.permalink-page {


  h1.caption {
    display: block;
    font-size: 11px;
    font-weight: normal;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  .post.photoset {
    font-size: 0
    position: relative;


    .thumbnail {
      position: relative;
      width: 50%;

      box-sizing: border-box;
      padding: 10px;
      cursor: pointer;
      display: inline-block;

      @media mobile-landscape {
        width: calc(100%/3);
      }
      @media desktop {
        width: 25%;
      }
      img {
        display: block;
        pointer-events: none;
        width: 100%;
        transition-duration: slow;
      }
      .caption {
        font-size: 11px;
        position: absolute;
        top: 50%;
        left: 10px;
        right: 10px;
        text-align: center;
        transform: translateY(-50%);
        color: transparent;
        transition-duration: slow;
      }

      &:hover {
        img {
          opacity: 0.20;
          transition-duration: fast;
        }
        .caption {
          color: #000;
          transition-duration: fast;
        }
      }


    }
  }


}


@media screen and ( max-height: 780px ){
  .lightbox .image {
    max-height:600px;
  }
}



.post.photoset.permapage {
  font-size: 0;
}


.photo .grid-sizer {
  cursor: pointer;
  display: block;
  pointer-events: none;
  width: 100%;
}

// Center Image ////////////////////////////////////////////////////////////////
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 80%;
}


.lightbox {


  span.photo {
    display: block;
    position: static;
    img {
      display: block;
      opacity: 0;
      transition: opacity fast ease-out;
      max-width: 100%;
      max-height: 100%;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .caption {
      opacity: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;

      display: block;
      line-height: 50px;
      transform: translateY(50px);
      transition: opacity fast ease-out;


    }

    &.current {
      img {
        opacity: 1;
        z-index: -1;
        transition: opacity fast ease-in fast;
        &.loading {
          opacity: 0;
        }
      }
      .caption {
        opacity: 1;
        transition: opacity fast ease-in fast;
      }
    }
  }



}




.lightbox-wrapper{
  position: relative;
}


// Capture Arrows

.capture {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
}



.lightbox {
  border: 50px solid transparent;
  pointer-events: none;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition-duration: slow;
  &.active {
    opacity: 1;
    pointer-events: auto;
    .capture{
      pointer-events: auto!important;
    }
    .right.capture {
      right: 0;
      cursor: e-resize;
    }
    .left.capture{
      left: 0;
      cursor: w-resize;
    }
    .esc {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      padding: 20px;
    }
    & + .esc {
      display: block !important;
    }
  }

}





.closebox {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: -50px;
  right: 0;
  margin: 0;
  padding: 15px 0;
  z-index: 400;
  line-height: 1;
  width: 50px;
  text-align: center;
}







// Permalink Page //////////////////////////////////////////////////////////////

.xspot {
  background:url("http://static.tumblr.com/nnzrmfd/PfZnfm84f/smallx.png");
  width: 19px;
  height: 18px;
  margin: 0 auto;
  background-position: 0 0;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition-duration: fast;
  }
}


body.post header,
body.post .lightbox {
  background: rgba(255, 255, 255, 0.7);
}


// Static Pages ////////////////////////////////////////////////////////////////


//          ABOUT STUFF        //

// NAV MODAL
#about,
#contact{
  opacity: 0;
  pointer-events: none;
  transition-duration: slow;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.expand {
    opacity: 1;
    pointer-events: auto;
  }
}

#about .description,
#contact .description {
  display: table-cell;
  vertical-align: middle;
  width: 500px;
  text-align: center;
  font-size: 11px;
  line-height: 1.5;
}


